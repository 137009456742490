<template>
  <div class="buyUpdateMsg">
    <div class="cell"></div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.buyUpdateMsg {
  padding: 20px;
  .cell {
    padding: 20px;
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
}
</style>